<template>
  <mc-modal :open="openModal" backdropcloseactiondisabled data-test="onboarding-modal"
    @closing="onModalClose">
    <span slot="heading">{{ heading }}</span>
    {{ content }}
    <mc-button appearance="Neutral" variant="filled" slot="secondaryAction" dialogaction="cancel">
      {{ cancelBtnLabel }}
    </mc-button>
    <mc-button slot="primaryAction" dialogaction="ok" @click="confirmAction()">
      {{ confirmBtnLabel }}
    </mc-button>
  </mc-modal>
</template>

<script>
import "@maersk-global/mds-components-core/mc-modal";
import "@maersk-global/mds-components-core/mc-button";

export default {
  name: "OnboardingModal",
  components: {},
  data() {
    return {};
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    cancelBtnLabel: {
      type: String,
      required: true,
    },
    confirmBtnLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    onModalClose() {
      this.$emit("closing");
    },
    confirmAction() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  justify-content: center;
}
</style>
