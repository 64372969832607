import applicationContext from "../ApplicationContext";
export const gcssChargeCodesDropdownList = [
  {
    value: "CCO",
    text: "Customs Clearance Origin (CCO)",
    type: "exportCustomsClearance",
  },
  {
    value: "CCD",
    text: "Customs Clearance Destination (CCO)",
    type: "importCustomsClearance",
  },
  {
    value: "CPO",
    text: "Port Filing Origin (CPO)",
    type: "exportCustomsClearance",
  },
  {
    value: "CPD",
    text: "Port Filing Destination (CPD)",
    type: "importCustomsClearance",
  },
  {
    value: "WAV",
    text: "ECTN/Waiver certificate (WAV)",
    type: "importCustomsClearance",
  },
  {
    value: "T1D",
    text: "T1 Transit Declaration (T1D)",
    type: "importCustomsClearance",
  },
  {
    value: "",
    text: "None",
    type: "all",
  },
];

export const consumerKeys = {
  cdt: "kvd6WYB79RatCCDwCHimpBgB2kRhGrp9",
  demo: "UPjsqkjlDAP7G9gIHAQjMGvXtJKKvdrG",
  prod: "MId2E12ZKnvQ7liE8NjDATg9GJnSfqAE",
};

const modifyPoaUrl = {
  cdt: "https://ot.dm-cdt.maersk.com/otcs/cs?func=ll&objid=263098&objaction=initiate",
  demo: "https://ot.dm-stage.maersk.com/otcs/cs?func=ll&objid=242300&objaction=initiate",
  prod: "https://ot.dm.maersk.com/otcs/cs?func=ll&objid=581620&objaction=initiate",
};

export const constants = {
  LD_BRAND: "maersk",
  LD_PREFIX: "MYCUSTOMS-ADMIN",
  featureFlags: {
    ONBOARDING: {
      name: "ONBOARDING",
      value: false,
    },
  },
  MODIFY_POA_TEMPLATE_URL: modifyPoaUrl[applicationContext.env],
  CUSTOM_DOC_TYPES: [
    {
      code: "PI",
      name: "Personal identification",
    },
    {
      code: "CD",
      name: "Company document",
    },
    {
      code: "OT",
      name: "Other",
    },
  ],
  ACTIONS: {
    SAVE: "save",
    DELETE: "delete",
    CANCEL: "cancel",
  }
};
