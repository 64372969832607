<template>
  <section>
    <div class="onboard-btn" v-if="showOnboardBtn">
      <mc-button
        appearance="neutral"
        label="Add Onboarding Country"
        icon="plus"
        @click="onOnboardClicked()"
      />
    </div>
    <div v-else data-test="onboarding">
      <LegalInformation />
      <AdditionalInfo />
      <CustomsDocs />
      <PageFooter
        @handleSubmit="onHandleSubmit"
        @handleCancel="onHandleCancel"
        @handleDelete="onHandleDelete"
      />
    </div>
    <OnboardingModal
      dimension="small"
      :openModal="openModal"
      :heading="labels.heading"
      :content="labels.content"
      :cancelBtnLabel="labels.cancelBtnLabel"
      :confirmBtnLabel="labels.confirmBtnLabel"
      @closing="onHandleClose"
      @confirm="onHandleConfirm"
    />
  </section>
</template>
<script>
import AdditionalInfo from "./AdditionalInfo.vue";
import LegalInformation from "./LegalInformation.vue";
import CustomsDocs from "./CustomsDocs";
import countryFilter from "@/filters/countryFilter";
import router from "@/router";
import PageFooter from "./PageFooter.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import OnboardingModal from "./modals/OnboardingModal.vue";
import i18nFilter from "@/filters/i18nFilter";
import { constants } from "../../../utilities/CONSTANTS";

export default {
  name: "Onboarding",
  components: {
    PageFooter,
    LegalInformation,
    AdditionalInfo,
    CustomsDocs,
    OnboardingModal,
  },
  data() {
    return {
      openModal: false,
      currentCta: "",
      labels: {
        heading: "",
        content: "",
        cancelBtnLabel: "",
        confirmBtnLabel: "",
      },
    };
  },
  computed: {
    ...mapState({
      onboardingDetails: (state) => state.onboardingDetails,
      isFormDataChanged: (state) =>
        state.editCountryController.isFormDataChanged,
      showOnboardBtn: (state) => state.showOnboardButton,
    }),
    countryName() {
      return router.currentRoute?.params?.countryName;
    },
    countryCode() {
      return countryFilter(this.countryName);
    }
  },
  methods: {
    ...mapMutations(["showOnboardButton"]),
    ...mapActions([
      "getOnboardingDetails",
      "updateOnboardingDetails",
      "deleteOnboardingDetails",
      "goToCountriesOverviewPage",
      "setEditCountryFormDataChanged",
    ]),
    async setInitialFormData() {
      if (this.countryCode) {
        await this.getOnboardingDetails(this.countryCode);
      }
    },
    onOnboardClicked() {
      this.showOnboardButton(false);
    },
    async onHandleSubmit() {
      this.currentCta = constants.ACTIONS.SAVE;
      const updateCountryReq = {
        countryName: this.countryName,
        legalInformation: this.onboardingDetails.legalEntity,
        additionalInformation: this.onboardingDetails.additionalInformation,
        requiredDocuments: this.onboardingDetails.customDocs,
        countryCode: this.countryCode,
      };
      await this.updateOnboardingDetails(updateCountryReq);
      this.setEditCountryFormDataChanged(false);
    },
    onHandleClose() {
      this.openModal = false;
      if (this.currentCta === constants.ACTIONS.CANCEL) {
        this.goToCountriesOverviewPage();
      }
    },
    async onHandleConfirm() {
      if (this.currentCta === constants.ACTIONS.CANCEL) {
        await this.onHandleSubmit();
      } else {
        await this.deleteOnboardingDetails(this.countryCode);
      }
      this.goToCountriesOverviewPage();
    },
    onHandleCancel() {
      if (this.isFormDataChanged) {
        const labelPrefix = "chbAdmin.editPage.modal.leaveWithoutSavingError";
        this.currentCta = constants.ACTIONS.CANCEL;
        this.openModal = true;
        this.labels = {
          heading: "",
          content: i18nFilter(`${labelPrefix}.description`),
          cancelBtnLabel: i18nFilter(`${labelPrefix}.button.cancel.label`),
          confirmBtnLabel: i18nFilter(`${labelPrefix}.button.submit.label`),
        }
      } else {
        this.goToCountriesOverviewPage();
      }
    },
    onHandleDelete() {
      const labelPrefix =
          "chbAdmin.editPage.form.editCountry.onboarding.deleteModal";
      this.currentCta = constants.ACTIONS.DELETE;
      this.openModal = true;
      this.labels = {
        heading: i18nFilter(`${labelPrefix}.heading`),
        content: i18nFilter(`${labelPrefix}.content`),
        cancelBtnLabel: i18nFilter(`${labelPrefix}.button.cancel.label`),
        confirmBtnLabel: i18nFilter(`${labelPrefix}.button.submit.label`),
      };
    },
  },
  async created() {
    await this.setInitialFormData();
  },
};
</script>
<style scoped lang="scss">
.onboard-btn {
  text-align: end;
}
</style>
