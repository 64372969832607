<template>
  <section class="additional-info" data-test="additional-info">
    <chb-section>
      <h3 class="font--display-2" data-test="section-title">
        {{ labels.sectionTitle }}
      </h3>
    </chb-section>
    <chb-section class="additional-info--content">
      <div>
        <div
          class="additional-info--content_item"
          data-test="enrolment-request"
        >
          <p>{{ labels.enrolmentRequest }}</p>
          <p v-if="!isEditEnabled">{{ email || "" }}</p>
          <mc-input
            v-else
            hiddenlabel
            :value="emailData"
            @input="onEmailChanged"
          />
        </div>
        <div
          class="additional-info--content_item"
          data-test="power-of-attorney"
        >
          <p>{{ labels.powerOfAttorney }}</p>
          <p>
            <span v-if="showDownloadTemplate">
              <a
                :href="poaViewUrl"
                target="_blank"
                data-test="view-template"
                rel="noopener noreferrer"
              >
                {{ labels.viewTemplate }}
                <mc-icon icon="file-eye" size="20" />
              </a>
            </span>
            <span>
              <a
                :href="modifyPoaUrl"
                target="_blank"
                data-test="modify-template"
                rel="noopener noreferrer"
              >
                {{
                  showDownloadTemplate
                    ? labels.modifyTemplate
                    : labels.addTemplate
                }}
                <mc-icon icon="pencil" size="20" />
              </a>
            </span>
          </p>
        </div>
      </div>
      <div class="add-info-edit">
        <div class="edit-icon" v-if="!isEditEnabled">
          <mc-icon class="pencil" icon="pencil" size="24" @click="editEmail" />
        </div>
        <div class="theme--light actions" v-if="isEditEnabled">
          <mc-button appearance="neutral" class="button--cancel" @click.prevent="handleCancel" :label="labels.cancel"/>
          <mc-button class="button--submit" @click.prevent="onSaveEmail" :label="labels.finish"/>
        </div>
      </div>
    </chb-section>
  </section>
</template>

<script>
import ChbSection from "@/components/common/ChbSection.vue";
import i18nFilter from "@/filters/i18nFilter";
import { constants } from "@/utilities/CONSTANTS";
import { mapState, mapMutations } from "vuex";

export default {
  name: "AdditionalInfo",
  components: {
    ChbSection,
  },
  data() {
    return {
      modifyPoaUrl: constants.MODIFY_POA_TEMPLATE_URL,
      isEditEnabled: false,
      emailData: "",
    };
  },
  computed: {
    ...mapState({
      additionalInformation: (state) =>
        state.onboardingDetails.additionalInformation,
    }),
    labels() {
      const prefix =
        "chbAdmin.editPage.form.editCountry.onboarding.additionalInformation";
      return {
        sectionTitle: i18nFilter(`${prefix}.sectionTitle`),
        enrolmentRequest: i18nFilter(`${prefix}.enrolmentRequestEmail`),
        powerOfAttorney: i18nFilter(`${prefix}.powerOfAttorney`),
        viewTemplate: i18nFilter(`${prefix}.viewTemplate`),
        modifyTemplate: i18nFilter(`${prefix}.modifyTemplate`),
        addTemplate: i18nFilter(`${prefix}.addTemplate`),
        cancel: i18nFilter("chbAdmin.common.cancel"),
        finish: i18nFilter("chbAdmin.common.finish"),
      };
    },
    email() {
      return this.additionalInformation?.enrollmentEmail;
    },
    showDownloadTemplate() {
      return this.additionalInformation?.templates?.length;
    },
    poaViewUrl() {
      return this.additionalInformation?.templates[0]?.url;
    },
  },
  methods: {
    ...mapMutations(["updateEnrollmentEmail", "setEditCountryFormDataChanged"]),
    onEmailChanged(event) {
      this.emailData = event.target.value;
    },
    editEmail() {
      this.isEditEnabled = true;
      this.setEmailData();
    },
    handleCancel() {
      this.isEditEnabled = false;
      this.setEmailData();
    },
    onSaveEmail() {
      this.isEditEnabled = false;
      this.updateEnrollmentEmail(this.emailData);
      this.setEditCountryFormDataChanged(true);
    },
    setEmailData() {
      this.emailData = this.email;
    },
  },
  mounted() {
    this.setEmailData();
  },
};
</script>

<style scoped lang="scss">
.actions {
  display: flex;
}

.additional-info {
  margin-top: 56px;
  &--content {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 24px;
    background: #f7f7f7;
    &_item {
      margin-bottom: 32px;

      a {
        color: #0073ab;
        margin-right: 20px;
        display: flex;
        align-items: center;
        i {
          margin-left: 4px;
        }
      }
      p:first-of-type {
        margin: 0;
        color: #878787;
        display: block;
      }
      p:not(first-of-type) {
        margin-top: 4px;
        display: inline-flex;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .add-info-edit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .edit-icon {
      flex: auto;
    }

    .pencil {
      display: flex;
      justify-content: flex-end;
      align-self: flex-start;
      color: #00243d;
      cursor: pointer;
    }
  }
}
</style>
