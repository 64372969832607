<template>
  <div id="chb-countries-configure">
    <div class="p-page__section theme--light" v-if="isLoading">
      <overlay-loading />
    </div>
    <ChbEditPageHeader />
    <template v-if="loggedInUser.isCHBAdmin">
      <template v-if="enableOnboarding">
        <chb-section class="chb-countries-configure__tabs theme--light--alt" data-test="edit-onboarding-tabs">
          <Tabs :tabs="tabs" @activated="handleTabChange" />
        </chb-section>
        <chb-section>
          <div v-if="activeTabIndex === 1">
            <chb-section>
              <h3 class="font--display-2" data-test="section-title">
                {{ "chbAdmin.editPage.header.subHeading.products" | i18n }}
              </h3>
            </chb-section>
            <ChbEditCountryForm />
            <ChbModal v-if="modalIsVisible" />
            <ChbEditPageFooter />
          </div>
          <div v-if="activeTabIndex === 2">
            <Onboarding data-test="onboarding-component"/>
          </div>
        </chb-section>
      </template>
      <template v-else>
        <ChbEditCountryForm />
        <ChbModal v-if="modalIsVisible" />
        <ChbEditPageFooter />
      </template>
    </template>
    <NoAccess v-else />
  </div>
</template>

<script>
import { getAndMapPorts } from "@/resources/portsMap";
import { mapState, mapActions } from "vuex";

import ChbSection from "../components/common/ChbSection.vue";
import ChbEditPageHeader from "../components/editCountry/products/ChbEditPageHeader";
import NoAccess from "../components/countriesOverviewPage/NoAccess";
import ChbEditCountryForm from "../components/editCountry/products/ChbEditCountryForm";
import i18nFilter from "../filters/i18nFilter";
import { OverlayLoading } from "@maersk/vue-core";
import { Tabs } from "@maersk/vue-tabs";
import { constants } from "@/utilities/CONSTANTS";
import { isFeatureEnabled } from "@/utilities/featureFlags";
import Onboarding from "../components/editCountry/onboarding/Onboarding.vue";
import router from "@/router";
import ChbModal from "../components/modal/ChbModal";
import ChbEditPageFooter from "../components/editCountry/products/ChbEditPageFooter";

// @ is an alias to /src
export default {
  name: "EditCountryPage",
  components: {
    ChbEditCountryForm,
    ChbEditPageHeader,
    OverlayLoading,
    NoAccess,
    Tabs,
    ChbSection,
    Onboarding,
    ChbModal,
    ChbEditPageFooter,
  },
  data() {
    return {
      loggedInUser: JSON.parse(window.localStorage.getItem("loggedInUserInfo")),
      activeTabIndex: 1,
      tabs: [
        {
          index: 1,
          id: "chb-edit-country-products",
          title: i18nFilter(
            "chbAdmin.editPage.form.editCountry.productsForm.label"
          ),
          isActive: true,
          ariaControls: i18nFilter(
            "chbAdmin.editPage.form.editCountry.productsForm.label"
          ),
        },
        {
          index: 2,
          id: "chb-edit-country-onboarding",
          title: i18nFilter(
            "chbAdmin.editPage.form.editCountry.onboardingForm.label"
          ),
          isActive: false,
          ariaControls: i18nFilter(
            "chbAdmin.editPage.form.editCountry.onboardingForm.label"
          ),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      modalIsVisible: (state) => state.modalController.isVisible,
      isLoading: (state) => state.isLoading,
    }),
    enableOnboarding() {
      return isFeatureEnabled(constants.featureFlags.ONBOARDING.name);
    },
    sectionTitle() {
      return this.activeTabIndex === 1
        ? i18nFilter("chbAdmin.editPage.header.subHeading.products")
        : i18nFilter("chbAdmin.editPage.header.subHeading.onboarding");
    },
  },
  methods: {
    ...mapActions(["resetEditPageController"]),
    handleTabChange(tab) {
      this.activeTabIndex = tab.index;
      switch (tab.index) {
        case 1:
          router.push({
            name: "EditCountryPage",
            params: { countryName: router.currentRoute.params.countryName },
          });
          break;
        case 2:
          router.push({
            name: "Onboarding",
            params: { countryName: router.currentRoute.params.countryName },
          });
          break;
      }
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === parseInt(this.activeTabIndex) - 1;
      });
    },
  },
  created() {
    getAndMapPorts();
    // Since this view contains form data, we want to notify user that all the data will be lost if page is refreshed
    window.onbeforeunload = () => true;
    this.$once("hook:destroyed", () => {
      //this.updateCountryInCountries();
      this.resetEditPageController();
      window.onbeforeunload = null;
    });
    // Set activeTabIndex to 2 if the route name is 'Onboarding'
    if (router.currentRoute.name === 'Onboarding') {
      this.handleTabChange(this.tabs[1]);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/settings";

#chb-countries-configure {
  padding-top: $chb-spacing-xsmall;
}
</style>
