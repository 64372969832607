<template>
  <div>
    <h3 class="font--display-2" data-test="section-title">
      {{ labels.sectionTitle }}
    </h3>
    <div id="onboarding-legal-info">
      <div class="form-container">
        <div>
          <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
            {{ labels.name }}
          </div>
          <div class="value" v-if="!isEditEnabled">
            {{ legalEntityData.name }}
          </div>
          <ChbInput
            v-else
            name="name"
            :inputValue="legalEntityData.name"
            @onUpdate="onUpdate"
          />
          <div class="label" :class="{ 'label-color-on-edit': isEditEnabled }">
            {{ labels.address }}
          </div>
          <div class="value" v-if="!isEditEnabled">
            {{ legalEntityData.address }}
          </div>
          <ChbTextArea
            v-else
            name="address"
            :inputValue="legalEntityData.address"
            @onUpdate="onUpdate"
          />
        </div>
        <div class="legal-entity-edit">
          <div class="edit-icon" v-if="!isEditEnabled">
            <mc-icon class="pencil" icon="pencil" size="24" @click="editLegalEntity" />
          </div>
          <div class="theme--light actions" v-if="isEditEnabled">
            <mc-button appearance="neutral" class="button--cancel" @click.prevent="handleCancel" :label="labels.cancel"/>
            <mc-button class="button--submit" @click.prevent="onSaveLegalEntity" :label="labels.finish"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChbTextArea from "@/components/common/ChbTextArea";
import ChbInput from "@/components/common/ChbInput";
import { mapMutations, mapState } from "vuex";
import i18nFilter from "@/filters/i18nFilter";

export default {
  name: "LegalInformation",
  components: {
    ChbTextArea,
    ChbInput,
  },
  data() {
    return {
      isEditEnabled: false,
      legalEntityData: {
        name: "",
        address: "",
      },
    };
  },
  computed: {
    ...mapState({
      legalEntity: (state) => state.onboardingDetails.legalEntity,
    }),
    labels() {
      const prefix = {
        legalEntity:
          "chbAdmin.editPage.form.editCountry.onboarding.legalInformation",
        common: "chbAdmin.common",
      };
      return {
        name: i18nFilter(`${prefix.legalEntity}.name`),
        address: i18nFilter(`${prefix.legalEntity}.address`),
        cancel: i18nFilter(`${prefix.common}.cancel`),
        finish: i18nFilter(`${prefix.common}.finish`),
        sectionTitle: i18nFilter(
          "chbAdmin.editPage.header.subHeading.onboarding"
        ),
      };
    },
  },
  methods: {
    ...mapMutations(["updateLegalEntity", "setEditCountryFormDataChanged"]),
    onUpdate({ fieldName, fieldValue }) {
      this.legalEntityData[fieldName] = fieldValue;
    },
    async onSaveLegalEntity() {
      this.isEditEnabled = false;
      const data = {
        entityName: this.legalEntityData.name,
        entityAddress: this.legalEntityData.address,
      };
      this.updateLegalEntity(data);
      this.setEditCountryFormDataChanged(true);
    },
    handleCancel() {
      this.isEditEnabled = false;
      this.setLegalEntityData();
    },
    editLegalEntity() {
      this.isEditEnabled = true;
    },
    setLegalEntityData() {
      this.legalEntityData.name = this.legalEntity.entityName;
      this.legalEntityData.address = this.legalEntity.entityAddress;
    },
  },
  watch: {
    legalEntity: {
      handler() {
        this.setLegalEntityData();
      },
      deep: true,
    },
  },
  mounted() {
    this.setLegalEntityData();
  },
};
</script>

<style lang="scss">
.actions {
  display: flex;
}

#onboarding-legal-info {
  background: #f7f7f7;

  .form-container {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-top: 24px;

    .value {
      margin-top: 8px;
      margin-bottom: 32px;
      color: #141414;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .label {
      color: #878787;
    }

    .label-color-on-edit {
      color: #141414;
    }

    .legal-entity-edit {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .edit-icon {
        flex: auto;
      }

      .pencil {
        display: flex;
        justify-content: flex-end;
        align-self: flex-start;
        color: #00243d;
        cursor: pointer;
      }
    }
  }
}
</style>
