import Vue from "vue";
import { generateUUID } from "@/formUtilities";
import _ from "lodash";
import { indexofPortForOperation } from "@/utilities/common";

const mutations = {
  updateChbCountries(state, chbCountries) {
    state.chbCountries = chbCountries.length > 0 ? chbCountries : [];
  },
  addNewCountry(state, listViewCountryData) {
    state.chbCountries.push(listViewCountryData);
  },
  setModalVisibility(state, bool) {
    state.modalController.isVisible = bool;
  },
  replaceGeoIdWithinLocCode(state, unLocCode) {
    state.modalController.data.formFields.geoId.value = unLocCode;
  },
  setModalContentComponent(state, contentComponent) {
    state.modalController.contentComponent = contentComponent;
  },
  setModalData(state, data) {
    state.modalController.data = data;
  },
  setModalHeaderText(state, text) {
    state.modalController.headerText = text;
  },
  setModalWidth(state, modalWidth) {
    state.modalController.modalWidth = modalWidth;
  },
  setModalSubmitButtonLabel(state, label) {
    state.modalController.submitButtonOptions.label = label;
  },
  setModalSubmitButtonIsDisabled(state, bool) {
    state.modalController.submitButtonOptions.isDisabled = bool;
  },
  setModalCancelButtonLabel(state, label) {
    state.modalController.cancelButtonOptions.label = label;
  },
  setModalCancelButtonVisibility(state, bool) {
    state.modalController.cancelButtonOptions.isVisible = bool;
  },
  setModalCancelButtonIsDisabled(state, bool) {
    state.modalController.cancelButtonOptions.isDisabled = bool;
  },
  setModalDeleteButtonVisibility(state, bool) {
    state.modalController.deleteButtonOptions.isVisible = bool;
  },
  setModalDeleteButtonIsDisabled(state, bool) {
    state.modalController.deleteButtonOptions.isDisabled = bool;
  },
  setModalDeleteButtonLabel(state, label) {
    state.modalController.deleteButtonOptions.label = label;
  },
  setModalCurrentPage(state, currentPage) {
  state.modalController.currentPage = currentPage;
  },
  updateModalAddCountryData(state, { fieldName, fieldValue }) {
    state.modalController.data.formFields[fieldName].value = fieldValue;
  },
  setEditCountryFormData(state, countryFormData) {
    state.editCountryController.formData = countryFormData;
  },
  updateCountry(state, country) {
    for (let key in country) {
      state.editCountryController.formData[key].value = country[key];
    }
  },
  updateCurrencyCode(state, { fieldName, fieldValue }) {
    state.editCountryController.formData[fieldName].value = fieldValue;
  },
  updateCountryInCountries(state, updatedCountry = null) {
    try {
      if (state && state.chbCountries.length && updatedCountry) {
        state.chbCountries.forEach((country) => {
          if (
            country &&
            updatedCountry.countryCode &&
            country.countryCode === updatedCountry.countryCode
          ) {
            country = Object.assign(country, updatedCountry);
          }
        });
      }
    } catch (err) {
      console.error("Cannot update countries.", err);
    }
  },
  deleteCountryInCountries(state, countryCode) {
    const index = state.chbCountries.findIndex(
      (el) => el.countryCode === countryCode
    );
    state.chbCountries.splice(index, 1);
  },
  setIsNewCountry(state, bool) {
    state.editCountryController.isNewCountry = bool;
  },
  setEditCountryIsFormValidated(state, bool) {
    state.editCountryController.isFormValidated = bool;
  },
  setEditCountryFormDataChanged(state, bool) {
    state.editCountryController.isFormDataChanged = bool;
  },
  setEditCountryIsFormValid(state, bool) {
    state.editCountryController.isFormValid = bool;
  },
  setIsEditEnabled(state, isEnabled) {
    state.editCountryController.isEditEnabled = isEnabled;
  },
  
  updateModalConfigureProductFormData(
    state,
    { fieldName, fieldValue, indexOfChargeLine }
  ) {
    if (indexOfChargeLine !== undefined) {
      state.modalController.data.formFields.chargeLines.value[
        indexOfChargeLine
      ][fieldName] = fieldValue;
    } else {
      state.modalController.data.formFields[fieldName].value = fieldValue;
    }
  },
  configureProductAddServiceMode(state, { fieldName, fieldValue }) {
    const index =
      state.modalController.data.formFields.supportedShipmentServiceModes.value.indexOf(
        fieldValue
      );
    if (index === -1) {
      if (fieldValue === "CY") {
        state.modalController.data.formFields.supportedShipmentServiceModes.value.splice(
          0,
          0,
          fieldValue
        );
      } else {
        state.modalController.data.formFields.supportedShipmentServiceModes.value.push(
          fieldValue
        );
      }
    } else {
      state.modalController.data.formFields.supportedShipmentServiceModes.value.splice(
        index,
        1
      );
    }
  },
  addNewProduct(state, { productGroup, product }) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const products = productGroups.find(
      (el) => el.productGroupId === productGroup
    ).products;
    if (!products.length) {
      const defaultProduct = products.filter(
        (product) => product.defaultProduct
      );
      if (!defaultProduct.length) {
        product.defaultProduct = true;
      }
    }
    product.ports = [];
    products.push(product);
  },
  addDuplicateProduct(
    state,
    { productGroup, duplicateProduct, originalProductId }
  ) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    let products = productGroups.find(
      (el) => el.productGroupId === productGroup
    ).products;
    const orginalProductIndex = products.findIndex(
      (element) => element.productId === originalProductId
    );
    products.splice(orginalProductIndex + 1, 0, duplicateProduct);
  },
  editExistingProduct(state, { productGroup, product }) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const products = productGroups.find(
      (el) => el.productGroupId === productGroup
    ).products;

    const indexOfProductToEdit = products.findIndex(
      (el) => el.productId === product.productId
    );

    Vue.set(products, indexOfProductToEdit, product);
  },
  deleteExistingProduct(state, { productGroup, product }) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const products = productGroups.find(
      (el) => el.productGroupId === productGroup
    ).products;

    const indexOfProductToDelete = products.findIndex(
      (el) => el.productId === product.productId
    );

    products.splice(indexOfProductToDelete, 1);
  },
  setFormErrorFields(state, { prop, isValid, errorText }) {
    state.modalController.data.formFields[prop].isValid = isValid;
    state.modalController.data.formFields[prop].errorText = errorText;
  },
  showFormErrorText(state, errorText) {
    state.modalController.data.formError.isVisible = true;
    state.modalController.data.formError.errorText = errorText;
  },
  hideFormErrorText(state) {
    state.modalController.data.formError.isVisible = false;
    state.modalController.data.formError.errorText = "";
  },
  setIsFormValidated(state, bool) {
    state.modalController.data.isFormValidated = bool;
  },
  setIsFormValid(state, bool) {
    state.modalController.data.isFormValid = bool;
  },
  setEditPageIsFormValidated(state, bool) {
    state.editCountryController.isFormValidated = bool;
  },
  setEditPageIsFormValid(state, bool) {
    state.editCountryController.isFormValid = bool;
  },
  setEditPageFormErrorFields(state, { prop, isValid, errorText }) {
    state.editCountryController.formData[prop].isValid = isValid;
    state.editCountryController.formData[prop].errorText = errorText;
  },
  updateModalConfigurePortChargesFormData(
    state,
    { fieldName, fieldValue, indexOfChargeLine }
  ) {
    if (indexOfChargeLine !== undefined) {
      state.modalController.data.formFields.chargeLines.value[
        indexOfChargeLine
      ][fieldName] = fieldValue;
    } else {
      state.modalController.data.formFields[fieldName].value = fieldValue;
    }
  },
  updateModalConfigurePortSupportedBrands(state, { fieldName, fieldValue }) {
    const index =
      state.modalController.data.formFields.supportedCarrierCodes.value.indexOf(
        fieldValue
      );
    if (index === -1) {
      if (fieldValue === "MAEU") {
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          0,
          0,
          fieldValue
        );
      } else if (fieldValue === "SEAU") {
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          1,
          0,
          fieldValue
        );
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          2,
          0,
          "MCPU"
        );
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          3,
          0,
          "SEJJ"
        );
      } else {
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          2,
          0,
          fieldValue
        );
      }
    } else {
      if (fieldValue === "SEAU") {
        state.modalController.data.formFields.supportedCarrierCodes.value =
          state.modalController.data.formFields.supportedCarrierCodes.value.filter(
            (item) => {
              return item !== "SEAU" && item !== "MCPU" && item !== "SEJJ";
            }
          );
      } else {
        state.modalController.data.formFields.supportedCarrierCodes.value.splice(
          index,
          1
        );
      }
    }
  },

  updateCurrentPort(state, port) {
    state.modalController.data.formFields = port;
  },
  // on Edit mode change listed ports inside modal
  updateCurrentProductPorts(state, { portName, geoId, portId }) {
    state.modalController.data.currentProductPorts.forEach((port, index) => {
      if (port.portId !== portId.value) {
        state.modalController.data.currentProductPorts.splice(
          index,
          state.modalController.data.currentProductPorts.length - 1
        );
      }
    });
    state.modalController.data.currentProductPorts[0].portName = portName.value;
    state.modalController.data.currentProductPorts[0].geoId =
      portName.value === "All Ports" ? "-999" : geoId.value;
  },

  clearExitingPorts(state, { productGroup, productId }) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const products = productGroups.find(
      (el) => el.productGroupId === productGroup
    ).products;
    let currentProductPorts = products.find(
      (product) => product.productId === productId
    );
    currentProductPorts.ports.splice(0, currentProductPorts.ports.length);
  },
  // on edit port clicked make changes to editController page data
  overrideExitingPortsWithNewPort(
    state,
    { productGroup, productId, updatedPort }
  ) {
    const productType =
      state.editCountryController.formData.productGroups.value;
    const productList = productType.find(
      (el) => el.productGroupId === productGroup
    );
    let currentProduct = productList.products.find(
      (el) => el.productId === productId
    );
    currentProduct.ports.forEach((port, index) => {
      if (port.portId !== updatedPort.portId) {
        currentProduct.ports.splice(index, currentProduct.ports.length - 1);
      }
    });
    currentProduct.ports[0].portName = updatedPort.portName;
    currentProduct.ports[0].geoId =
      updatedPort.portName === "All Ports" ? "-999" : updatedPort.geoId;
  },

  addNewPort(state, { productGroup, productId, updatedPort }) {
    const productType =
      state.editCountryController.formData.productGroups.value;
    const productList = productType.find(
      (el) => el.productGroupId === productGroup
    );
    let currentProduct = productList.products.find(
      (el) => el.productId === productId
    );
    currentProduct.ports.push(updatedPort);
  },

  editExistingPort(state, { productGroup, updatedPort, productId }) {
    const productType =
      state.editCountryController.formData.productGroups.value;
    const productList = productType.find(
      (el) => el.productGroupId === productGroup
    );
    let currentProduct = productList.products.find(
      (el) => el.productId === productId
    );
    const index = currentProduct.ports.findIndex(
      (portItem) => portItem.portId === updatedPort.portId
    );
    Vue.set(currentProduct.ports, index, updatedPort);
  },
  addNewPriceLineOnCurrentFormItem(state, chargeType) {
    state.modalController.data.formFields.chargeLines.value.push({
      chargeId: generateUUID(),
      chargeType: chargeType,
      chargeUnit: "BILL_OF_LADING",
      unitPrice: Number(0.0).toFixed(2),
    });
  },
  addDuplicatePriceLineOnCurrentItem(state, { chargeLine, index }) {
    let duplicatePriceLineItem = {
      chargeId: generateUUID(),
      chargeType: chargeLine.chargeType,
      chargeUnit: chargeLine.chargeUnit,
      unitPrice: chargeLine.unitPrice,
    };
    state.modalController.data.formFields.chargeLines.value.splice(
      index + 1,
      0,
      duplicatePriceLineItem
    );
  },
  deletePriceLineOnCurrentFormItem(state, indexOfPriceLineToDelete) {
    state.modalController.data.formFields.chargeLines.value.splice(
      indexOfPriceLineToDelete,
      1
    );
  },
  showErrorPage(state, bool) {
    state.showErrorPage = bool;
  },
  setProductToDelete(state, { product, productGroup }) {
    state.productToDelete.product = product;
    state.productToDelete.productGroup = productGroup;
  },
  unsetProductToDelete(state) {
    state.productToDelete.product = null;
    state.productToDelete.productGroup = "";
  },
  deleteCurrentPort(state, port) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const portInfo = indexofPortForOperation(productGroups, port);
    const { portsList, indexOfCurrentPort } = portInfo;
    portsList.splice(indexOfCurrentPort, 1);
  },
  duplicateCurrentPort(state, { port, duplicatePort }) {
    const productGroups =
      state.editCountryController.formData.productGroups.value;
    const portInfo = indexofPortForOperation(productGroups, port);
    const { portsList, indexOfCurrentPort } = portInfo;
    portsList.splice(indexOfCurrentPort + 1, 0, duplicatePort);
  },
  setOriginalCountryDataValues(state) {
    state.originalCountryProductsData = _.cloneDeep(
      state.editCountryController.formData.productGroups
    );
    state.originalCountryData = _.cloneDeep({
      countryCode: state.editCountryController.formData.countryCode,
      countryContactEmail:
        state.editCountryController.formData.countryContactEmail,
      countryContactPhone:
        state.editCountryController.formData.countryContactPhone,
      countryName: state.editCountryController.formData.countryName,
      countryUploadDocumentEmail:
        state.editCountryController.formData.countryUploadDocumentEmail,
      currencyCode: state.editCountryController.formData.currencyCode,
    });
  },
  setLoaderState(state, isLoading) {
    state.isLoading = isLoading;
  },
  enableAddProductButton(state, isEnabled) {
    state.isAddProductEnabled = isEnabled;
  },
  removePortNameValue(state) {
    state.modalController.data.formFields.portName.value = "";
  },
  overrideCurrentPortsData(state, overridePorts) {
    state.overrideCurrentPortsData = overridePorts;
  },
  showOnboardButton(state, showButton) {
    state.showOnboardButton = showButton;
  },
  clearOnboardingData(state) {
    state.onboardingDetails.additionalInformation = {
      enrollmentEmail: "",
      templates: ""
    };
    state.onboardingDetails.customDocs = [];
    state.onboardingDetails.legalEntity = {
      data: {
        name: "",
        address: ""
      }
    };
  },
  updateLegalEntity(state, legalEntityData) {
    state.onboardingDetails.legalEntity = legalEntityData;
  },
  updateAdditionalInformation(state, additionalInformationData) {
    state.onboardingDetails.additionalInformation = additionalInformationData;
  },
  updateCustomDocs(state, customDocs) {
    state.onboardingDetails.customDocs = customDocs;
  },
  updateEnrollmentEmail(state, email) {
    state.onboardingDetails.additionalInformation.enrollmentEmail = email;
  }
};

export default mutations;
