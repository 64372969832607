<template>
  <chb-section class="onboarding-page-footer" data-test="onboarding-page-footer">
    <mc-button
      appearance="neutral"
      :label="labels.delete"
      @click="$emit('handleDelete')"
    ></mc-button>
    <div class="footer-cta">
      <mc-button
        appearance="neutral"
        :label="labels.cancel"
        @click="$emit('handleCancel')"
      ></mc-button>
      <mc-button
        appearance="primary"
        :disabled="!isFormDataChanged"
        :label="labels.save"
        @click="$emit('handleSubmit')"
      ></mc-button>
    </div>
  </chb-section>
</template>

<script>
import "@maersk-global/mds-components-core/mc-button";
import ChbSection from "@/components/common/ChbSection";
import i18nFilter from "@/filters/i18nFilter";
import { mapState } from "vuex";

export default {
  name: "PageFooter",
  components: {
    ChbSection,
  },
  computed: {
    ...mapState({
      isFormDataChanged: (state) =>
        state.editCountryController.isFormDataChanged,
    }),
    labels() {
      const labelPrefix =
        "chbAdmin.editPage.form.editCountry.button";
      return {
        save: i18nFilter(`${labelPrefix}.submit.label`),
        cancel: i18nFilter(`${labelPrefix}.cancel.label`),
        delete: i18nFilter(`${labelPrefix}.delete.label`),
      };
    },
  },
};
</script>
<style scoped>
.onboarding-page-footer {
  display: grid;
  grid-template-columns: auto auto;
  .footer-cta {
    display: grid;
    justify-content: end;
    grid-template-columns: max-content max-content;
    gap: 10px;
  }
}
</style>
