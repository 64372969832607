import axios from "axios";
import applicationContext from "@/ApplicationContext";
import { getAuthHeaders } from "@/authentication";

export const fetchOnboardingData = async (countryCode) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${applicationContext.contentLocation}/customs-services/customs-rules/customs-onboard/${countryCode}`,
      headers: getAuthHeaders(),
    });
    const { result } = response.data;
    return result;
  } catch (errorResponse) {
    throw errorResponse;
  }
};

export const updateOnboardingDetails = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${applicationContext.contentLocation}/customs-services/customs-rules/customs-onboard`,
      headers: getAuthHeaders(),
      data
    });
    const { result } = response.data;
    return result;
  } catch (errorResponse) {
    throw errorResponse;
  } 
};

export const deleteOnboardingDetails = async (countryCode) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${applicationContext.contentLocation}/customs-services/customs-rules/customs-onboard/${countryCode}`,
      headers: getAuthHeaders(),
    });
    const { message } = response.data;
    return message;
  } catch (errorResponse) {
    throw errorResponse;
  }
}
